<template>
  <!-- <div class="user">
    <div class="user_bg"></div>
    <div class="info" >
      <img class="profile" src="../assets/default_profile.png" />
      <span class="uname">{{ userName }}</span>
    </div>
  </div> -->

  <van-cell-group inset style="margin-top: 40px">
    <van-cell title="用户" :value="userName"   />
    <van-cell title="账号管理" :value="account" is-link @click="toResetPwd" />
    <van-cell title="用户信息"  is-link @click="toUpdateUserInfo" />
    <!-- <van-cell title="切换缴费用户" is-link @click="toUserList" /> -->
    <van-cell title="关于" is-link @click="toAbout" />
  </van-cell-group>

  <div style="margin: 100px 30px">
    <van-button round block color="#FD962D" plain native-type="submit" @click="onLogout">
      退出登录
    </van-button>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { Cell, Dialog, CellGroup, Toast } from "vant";
import { isAndroid, safeName } from "../common/js/utils";
import api from "../service/api";
import { clearStorage, getStorage, setStorage } from "../common/js/storeUtil";
import { AESencrypt } from "../common/js/encryptUtils";

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  setup() {
    const router = useRouter(); //传值
    const userName = ref("");
    const account = ref("");

    const toAbout = () => {
      router.push({
        path: "/about",
      });
    };
    const onLogout = () => {
      alertDialog();
    };
    //去重置密码页面
    const toResetPwd = () => {
      router.push({
        path: "/resetPwd",
      });
    };


    //去更改姓名和身份证页面
    const toUpdateUserInfo = () => {
      router.push({
        path: "/userInfo",
      });
    };

    function alertDialog() {
      // Dialog.confirm({
      //   title: "解除绑定",
      //   message:
      //     "解除与" + userName.value + "的绑定关系后将无法为该用户缴费,确定要解除绑定吗?",
      // })
      Dialog.confirm({
        title: "退出登录",
        message:
          "确定要退出登录吗?",
      })
        .then(() => {
          //解除成功清除数据跳转到首页
          clearStorage();
                router.replace({
                  path: "/",
                });
                Dialog.close;
          // api
          //   .userUnbind({
          //     user_no: getStorage("userNo"),

          //   })
          //   .then((res) => {
          //     if (res.data.code == 1001) {
          //       //解除成功清除数据跳转到首页
          //       clearStorage();
          //       router.replace({
          //         path: "/",
          //       });
          //       Dialog.close;
          //     } else {
          //       Dialog.close;
          //       Toast("解除绑定失败");
          //     }
          //   })
          //   .catch(() => {
          //     Dialog.close;
          //   });
        })
        .catch(() => {
          Dialog.close;
        });
    }
    // const toUserList = () => {
    
    //   router.replace({
    //     path: "/bindlist2",
    //   });
    // };

    onMounted(() => {
      /* eslint-disable */
      //setTitle("用户中心");
        userName.value = getStorage("userName");// safeName(getCookie("userName"));
        account.value =  getStorage("userNo");
      
    });

    //账号中间加*号
    function mobileStr(str) {
      if (str) {
        return str.substring(0, 3) + "****" + str.substring(7, str.length);
      } else {
        return "";
      }
    }

    return {
      onLogout,
      toAbout,
      userName,
      account,
      toUpdateUserInfo,
      toResetPwd,
    };
  },
};
</script>

<style lang="less">
.user {
  position: relative;
  width: 100%;
  z-index: -1;
  height: 180px;
  overflow: hidden;

  .user_bg {
    position: absolute;
    width: 140%;
    height: 180px;
    left: -20%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    background-image: linear-gradient(0deg, #1989fa, #1989fa 50%);
    z-index: -1;
  }

  .info {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;

    .profile {
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: 0 auto;
    }
    .uname {
      display: block;
      width: 120px;
      margin-top: 10px;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
>
